<script>
	import GlobalVue from '../../helper/Global.vue'
	export default {
		extends: GlobalVue,
		data() {
			return {
				input: {},
			}
		},
		computed: {
			upic() {
				return this.user && Gen.userToken() ? this.user.pic : ''
			},
			uemail() {
				return this.user && Gen.userToken() ? this.user.email : ''
			},
			ufullname() {
				return this.user && Gen.userToken() ? this.user.fullname : ''
			},
			myParams() {
				let query = Gen.clone(this.$route.query)
				return query
			}
		},
		mounted() {
			this.$emit("ready", this)
			this.getData()
		},
		methods: {
			open(params) {
				global.Modal = this
				$(this.$el).modal()
				this.$set(this.input, 'type', "0")
			},
			onSearch() {

			},
			closeModal() {
				$(this.$el).modal("hide")
			},
			getData() {
				Gen.apirest("/get-account-setting", {}, (err, resp) => {
					if (err) return console.log(err)
					this.input = resp.data
				})
			},
			onSubmit() {
				this.input.fullname = this.input.fullname.replace(/[0-9]/g, '')
				Gen.apirest("/submit-account-setting", this.input, (err, resp) => {
					$("#accSetting").scrollTop(0)
					if (err) return Gen.info(err.resp.message, 'warning')
					this.getData()
					this.refreshVariableFo()
					$('.foto_profile').attr('src', this.uploader(resp.data.pic))
					Gen.info(resp.message, 'success')
					setTimeout(() => {
						$(this.$el).modal("hide")
					}, 1500);
				}, "POST")
			},
		},
		watch: {}
	};
</script>

<template>
	<!-- Pilih Desain-->
	<div class="modal bg-transparent fade" id="accSetting" tabindex="-1" role="dialog">
		<div class="modal-dialog" role="document" data-aos="fade-up" data-aos-duration="1000">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title" id="exampleModalLongTitle">{{web.dsh_edit_your_profile}}</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true"><i class="icon-line-cross"></i></span>
					</button>
				</div>
				<VForm @resp="onSubmit">
					<div class="modal-body dark" data-aos="fade-up" data-aos-duration="1000">
						<div class="info"></div>
						<div class="form-row">
							<div class="col-md-12 text-center mb-3">
								<img v-if="input.pic"  class="rounded-circle" :src="uploader(input.pic)" />
							</div>
							<div class="col-md-6">
								<div class="form-group mb-4">
									<label for="">{{web.dsh_foto_profile}}</label>
									<Uploader name="pic" noPreview noHelp type="fo_user" isFrontend
										uploadType="cropping" v-model="input.pic"></Uploader>
								</div>
							</div>
							<div class="col-md-12">
								<div class="form-group">
									<label for="">{{web.dsh_name}}</label>
									<input type="text" v-model="input.fullname" name="fullname"
										onkeydown="return fullNameKey(event)" class="frm_custom_sign"
										v-bind="validation('fullname')" placeholder="Full Name">
									<LabelError name="fullname"></LabelError>
								</div>
							</div>
							<div class="col-md-12">
								<div class="form-group">
									<label for="">{{web.lbl_no_handphone}}</label>
									<input type="text" v-if="!isMobile" v-model="input.phone" name="phone"
										onkeydown="return phoneKey(event)" v-bind="validation('phone')"
										class="frm_custom_sign" placeholder="No. Handphone">
									<input type="tel" v-if="isMobile" v-model="input.phone" name="phone"
										onkeydown="return phoneKey(event)" v-bind="validation('phone')"
										class="frm_custom_sign" placeholder="No. Handphone">
									<LabelError name="phone"></LabelError>
								</div>
							</div>
							<div class="col-md-12">
								<div class="form-group">
									<label for="">{{web.dsh_email_address}}</label>
									<input type="text" name="email" onkeydown="return emailKey(event)"
										v-bind="validation('email')" class="frm_custom_sign" :value="uemail"
										placeholder="Email Address">
									<LabelError name="email"></LabelError>
								</div>
							</div>
						</div>
						<div class="form-group">
							<label for="" class="d-block">{{web.dsh_password}}</label>
							<a data-toggle="collapse" href="#ChangePassword" role="button" aria-expanded="false"
								aria-controls="ChangePassword" class="btn_change_password"><i
									class="icon-line2-lock-open"></i>
								{{web.btn_change}} </a>
						</div>
						<div class="collapse mt-3" id="ChangePassword">
							<div class="form-group">
								<input type="password" v-model="input.password" name="password" id="password"
									v-bind="validation('password')" class="frm_custom_sign" placeholder="New Password">
								<LabelError name="password"></LabelError>
							</div>
							<div class="form-group">
								<input type="password" v-model="input.confirm_password" name="confirm_password"
									v-bind="validation('confirm_password')" class="frm_custom_sign"
									placeholder="Confirm  Password">
								<LabelError name="confirm_password"></LabelError>
							</div>
						</div>
					</div>
					<div class="modal-footer text-right" style="display: block;">
						<button type="submit" class="btn_setting mt-0">{{web.btn_submit}} <i
								class="icon-line-arrow-right"></i></button>
					</div>
				</VForm>
			</div>
		</div>
	</div>
</template>